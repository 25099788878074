<template>
    <v-container>
        <v-row>
            <v-col class="pt-0">
                <h2 class="primary--text"> Mes réservations </h2>
            </v-col>
        </v-row>

        <!-- Quick search and pagination -->
        <v-row class="pb-4" align="center" no-gutters>
            <v-col cols="12" md="2" class="mb-2 mb-md-0">
                <v-text-field class="mr-2" v-model="quickSearch" @keydown.enter="fetchSales(true)" label="Recherche..." dense hide-details outlined clearable>
                    <template v-slot:append>
                        <v-icon color="grey" @click="fetchSales(true)"> fas fa-search </v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="5" class="mb-2 mb-md-0">
                <v-btn @click="displayAdvanceSearch = !displayAdvanceSearch" class="mr-2 mb-2 mb-md-0" color="primary" outlined depressed small>
                    <v-icon v-if="!displayAdvanceSearch" left x-small> fas fa-plus </v-icon>
                    <v-icon v-if="displayAdvanceSearch" left x-small> fas fa-minus </v-icon>
                    de filtres
                </v-btn>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn small text class="grey lighten-2 mr-2 mb-2 mb-md-0" v-on="on">
                            {{ salesCount }}
                        </v-btn>
                    </template>
                    Nombre total de réservations
                </v-tooltip>

                <CreateOptionDialog v-if="getConfig('sales.enabled', false) && !getConfig('sales.sale_must_be_created_from_option', true)" @reload="fetchSales(false, false)" :isOption="false" :initProgramId="this.programs[0]">
                    <template v-slot:activator="{ on: menu }">
                        <v-btn color="primary" depressed small dark v-on="{ ...menu }">
                            <v-icon x-small left>
                                fas fa-shopping-cart
                            </v-icon>
                            Enregistrer une réservation
                        </v-btn>
                    </template>
                </CreateOptionDialog>
            </v-col>

            <v-col cols="12" md="5" class="text-right">
                <v-select class="sort-pagination d-inline-flex" v-model="order" label="Trier par" :menu-props="{ bottom: true, offsetY: true }" :items="salesSortItems" background-color="white" @change="fetchSales(true)" hide-details outlined dense>
                    <template v-slot:append-outer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2">
                                    <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                        fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template v-if="by === 'asc'">
                                Croissant
                            </template>
                            <template v-if="by === 'desc'">
                                Décroissant
                            </template>
                        </v-tooltip>
                    </template>
                </v-select>

                <v-pagination v-model="page" @input="fetchSales(true, false)" :length="totalPages" :total-visible="7" class="pagination d-inline-block" color="primary" />
            </v-col>
        </v-row>

        <!-- Advance search -->
        <v-expand-transition>
            <v-card v-show="displayAdvanceSearch" elevation="0">
                <v-sheet color="grey lighten-3" class="pa-4 mb-4">
                    <v-row class="mt-0">
                        <v-col cols="12" lg="3">
                            <SaleStatusSelect v-model="statusesFilter" multiple />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <DatePickerMenu v-model="modifiedDateFilter" label="Date de mise à jour" range />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <ContactAutocomplete v-model="contactsFilter" multiple useVisitor />
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col cols="12" lg="3">
                            <ProgramAutocomplete ref="programAutocomplete" v-model="programsFilter" :disabled="embeded" multiple noDynamicSearch searchOnly label="Programmes" />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <ProductAutocomplete ref="productAutocomplete" v-model="productsFilter" multiple noDynamicSearch />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-btn color="primary" class="mr-4" dark depressed small @click="fetchSales(true)">
                                <v-icon left x-small> fas fa-search </v-icon>
                                Rechercher
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-card>
        </v-expand-transition>

        <v-row v-if="sales.length > 0">
            <v-col v-for="sale of sales" :key="sale.id" outlined cols="12" md="6" xl="4">
                <router-link :to="getConfig('sales.enabled', false) ? `/reservations/${sale.id}` : ''" class="no-text-decoration" :class="{ 'cursor-default': !getConfig('sales.enabled', false) }">
                    <v-alert border="left" elevation="1" :color="getSaleStatus(sale.status, 'color')" colored-border>
                        <div class="pa-2 mb-2">
                            <v-row>
                                <v-col>
                                    <v-tooltip :disabled="!sale.statusdatelabel" top>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <v-icon :color="getSaleStatus(sale.status, 'color') + ' '" left> {{ getSaleStatus(sale.status, 'icon') }} </v-icon>
                                                {{ getSaleStatus(sale.status, 'label') }}
                                            </div>
                                        </template>
                                        {{ sale.statusdatelabel }}: {{ sale.statusdate | toDate() }}
                                    </v-tooltip>

                                    <br>

                                    <v-chip :color="getSaleStatus(sale.status, 'color') + '  white--text'" label small class="mt-1">
                                        <v-icon left x-small> fas fa-user </v-icon>
                                        {{ sale.contact.name }} {{ sale.contact.firstname }}
                                    </v-chip>

                                    <br>

                                    <small class="pl-1">
                                        {{ sale.contact.email }}
                                    </small>
                                    <br>
                                    <small class="pl-1">
                                        {{ sale.contact.mobile }}
                                    </small>
                                </v-col>

                                <v-col class="text-right">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" :color="getSaleStatus(sale.status, 'color') + '  white--text'" class="mb-1" label small>
                                                {{ sale.program.title }}
                                            </v-chip>
                                        </template>
                                        {{ sale.program.city }}
                                    </v-tooltip>

                                    <br>

                                    <v-chip :color="getSaleStatus(sale.status, 'color') + '  white--text'" class="my-1" label small>
                                        {{ sale.product.reference }} {{ sale.product.typeLabel }} {{ sale.product.roomsLabel }}
                                    </v-chip>

                                    <br>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <small class="mr-4" v-on="on"> {{ sale.vat | round(1) }} % </small>
                                        </template>
                                        Taux TVA
                                    </v-tooltip>

                                    <span> {{ getSalePriceTTC(sale) | toCurrencyString() }} </span>
                                </v-col>
                            </v-row>
                        </div>

                        <v-sheet :colora="getSaleStatus(sale.status, 'color') + ' lighten-1'">
                            <v-row>
                                <v-col cols="3" class="text-center py-1">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <v-icon :color="getSaleStatus(sale.status, 'color')" left x-small> fas fa-file </v-icon>
                                                <small class="ma-0 pa-0">{{ sale.date |toDate() }}</small>
                                            </div>
                                        </template>
                                        Date de pré réservation
                                    </v-tooltip>
                                </v-col>

                                <v-col cols="3" class="text-center py-1">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <small v-if="sale.reserveddate" class="ma-0 pa-0">
                                                    <v-icon :color="getSaleStatus(sale.status, 'color')" left x-small> fa fa-file-contract </v-icon>
                                                    {{ sale.reserveddate |toDate() }}
                                                </small>
                                                <small v-else>
                                                    <v-icon color="grey ligthen-2" left x-small> fa fa-file-contract </v-icon>
                                                </small>
                                            </div>
                                        </template>
                                        Date de signature du contrat préliminaire
                                    </v-tooltip>
                                </v-col>

                                <v-col cols="3" class="text-center py-1">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <small v-if="sale.srusentdate" class="ma-0 pa-0">
                                                    <v-icon :color="getSaleStatus(sale.status, 'color')" left x-small> fa fa-envelope </v-icon>
                                                    {{ sale.srusentdate |toDate() }}
                                                </small>
                                                <small v-else>
                                                    <v-icon color="grey ligthen-2" left x-small> fa fa-envelope </v-icon>
                                                </small>
                                            </div>
                                        </template>
                                        Date d'envoi courrier SRU
                                    </v-tooltip>
                                </v-col>

                                <v-col cols="3" class="text-center py-1">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <small v-if="sale.deeddate" class="ma-0 pa-0">
                                                    <v-icon :color="getSaleStatus(sale.status, 'color')" left x-small> fa fa-feather-alt </v-icon>
                                                    {{ sale.deeddate | toDate() }}
                                                </small>
                                                <small v-else-if="sale.status === 'notaryappset'" class="ma-0 pa-0 font-italic">
                                                    <v-icon :color="getSaleStatus(sale.status, 'color')" left x-small> fa fa-feather-alt </v-icon>
                                                    {{ sale.planneddeeddate | toDate() }}
                                                </small>
                                                <small v-else>
                                                    <v-icon color="grey ligthen-2" left x-small> fa fa-feather-alt </v-icon>
                                                </small>
                                            </div>
                                        </template>

                                        <template v-if="sale.deeddate"> Date de signature notaire </template>
                                        <template v-else-if="sale.status === 'notaryappset'"> Date de signature notaire prévue </template>
                                        <template v-else> Date de signature notaire à prévoir </template>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-alert>
                </router-link>
            </v-col>
        </v-row>

        <v-row align="center" justify="center" style="min-height: 300px">
            <v-col v-if="sales.length === 0">
                <h1 class="text-center grey--text"> Aucune réservation </h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import salesMixin from '../mixins/sales.js';

import DatePickerMenu from '../components/widgets/DatePickerMenu.vue';
import SaleStatusSelect from '../components/widgets/SaleStatusSelect.vue';
import CreateOptionDialog from '../components/dialogs/CreateOptionDialog.vue';
import ContactAutocomplete from '../components/widgets/ContactAutocomplete.vue';
import ProductAutocomplete from '../components/widgets/ProductAutocomplete.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';

export default {
    name: 'Sales',

    mixins: [salesMixin],

    props: {
        programs: { type: Array, default: () => ([]) },
        embeded: { type: Boolean, default: false }
    },

    components: {
        DatePickerMenu,
        SaleStatusSelect,
        CreateOptionDialog,
        ContactAutocomplete,
        ProductAutocomplete,
        ProgramAutocomplete
    },

    data: () => ({
        page: 1,
        totalPages: 2,
        quickSearch: '',
        salesCount: 0,
        limit: 15,
        order: 'modified',
        by: 'desc',

        sales: [],

        displayAdvanceSearch: false,

        programsFilter: [],
        productsFilter: [],
        statusesFilter: [],
        locationsFilter: [],
        contactsFilter: [],
        modifiedDateFilter: '',

        disableNavigation: false,
        advanceSearchDataFetched: false,

        salesSortItems: [
            { text: 'Date de mise à jour', value: 'modified' },
            { text: 'Nom du programme', value: 'program.title' },
            { text: 'Date de pré réservation', value: 'date' },
            { text: 'Date de pré contrat', value: 'reserveddate' },
            { text: 'Date de signature notaire', value: 'deeddate' },
            { text: 'Date d\'annulation', value: 'abortdate' },
            { text: 'Nom du prospect', value: 'contact.name' },
            { text: 'Référence du lot', value: 'product.reference' }
        ]
    }),

    methods: {
        buildQuery() {
            const query = {
                page: this.page,
                limit: this.limit,
                orderBy: `${this.order}:${this.by}`,
                include: 'buyers,products'
            };
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.productsFilter) && this.productsFilter.length > 0) {
                query.products = this.productsFilter.join(',');
            }
            if (Array.isArray(this.statusesFilter) && this.statusesFilter.length > 0) {
                query.statuses = this.statusesFilter.join(',');
            }
            if (Array.isArray(this.contactsFilter) && this.contactsFilter.length > 0) {
                query.contacts = this.contactsFilter.join(',');
            }
            if (Array.isArray(this.modifiedDateFilter) && this.modifiedDateFilter.length > 0) {
                query.modified = this.modifiedDateFilter.join(',');
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            return query;
        },

        buildDisplayQuery() {
            const query = {};
            if (this.page !== 1) {
                query.page = this.page;
            }
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.productsFilter) && this.productsFilter.length > 0) {
                query.products = this.productsFilter.join(',');
            }
            if (Array.isArray(this.statusesFilter) && this.statusesFilter.length > 0) {
                query.statuses = this.statusesFilter.join(',');
            }
            if (Array.isArray(this.contactsFilter) && this.contactsFilter.length > 0) {
                query.contacts = this.contactsFilter.join(',');
            }
            if (Array.isArray(this.modifiedDateFilter) && this.modifiedDateFilter.length > 0) {
                query.modified = this.modifiedDateFilter.join(',');
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            if (this.order !== 'program.title') {
                query.order = this.order;
                query.by = this.by;
            }
            return query;
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.limit = query.limit ? parseInt(query.limit) : this.limit;
            this.programsFilter = query.programs ? query.programs.split(',').map((p) => parseInt(p)) : [];
            this.productsFilter = query.products ? query.products.split(',').map((p) => parseInt(p)) : [];
            this.contactsFilter = query.contacts ? query.contacts.split(',').map((c) => parseInt(c)) : [];
            this.statusesFilter = query.statuses ? query.statuses.split(',') : [];
            this.modifiedDateFilter = query.modified ? query.modified.split(',') : [];
            this.quickSearch = query.quickSearch || null;
            this.order = query.order ? query.order : this.order;
            this.by = query.by ? query.by : this.by;
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        },

        async fetchSales(push, resetPage = true) {
            try {
                this.disableNavigation = true;
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (!this.embeded && push && this.doPush(this.buildDisplayQuery())) {
                    this.$router.push({ query: this.buildDisplayQuery() });
                }

                const query = this.buildQuery();
                const { sales, count, totalPages, err } = await this.repos.sales.getSales(query);
                if (err) {
                    throw new Error(err);
                } else {
                    this.sales = sales;
                    this.salesCount = count;
                    this.totalPages = totalPages;
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.disableNavigation = false;
                this.setLoading(false);
            }
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchSales(true);
        }
    },

    watch: {
        $route(to) {
            if (!this.disableNavigation) {
                if (!this.embeded) {
                    this.parseQuery(to.query);
                }
                this.fetchSales(false);
            }
        },

        displayAdvanceSearch() {
            if (this.displayAdvanceSearch && !this.advanceSearchDataFetched) {
                this.advanceSearchDataFetched = true;
                this.$refs.programAutocomplete.fetchPrograms({ limit: 1000 });
                this.$refs.productAutocomplete.fetchProducts({
                    limit: 1000,
                    productsReservationPartner: 1
                });
            }
        }
    },

    created() {
        if (this.embeded) {
            this.programsFilter = this.programs;
            this.salesSortItems = this.salesSortItems.filter((i) => i.value !== 'program.title');
        } else {
            this.parseQuery(this.$route.query);
        }
        this.fetchSales(false);
    }
};
</script>


<style lang="scss" scoped>
.pagination::v-deep .v-pagination {
    width: initial;
    max-width: initial;

    .v-pagination__navigation:last-child {
        margin-right: 0;
    }
}

.sort-pagination::v-deep {
    .v-input__control {
        max-width: 300px;
    }

    .v-input__append-outer {
        margin: 0 !important;
    }
}
</style>